// @ts-strict-ignore
import { ProductSliderFeaturedImage } from './FeaturedImage'
import { ProductCard } from '@components/ProductCard'
import {
  Box,
  Container,
  Flex,
  Heading,
  SliderNavButton,
  Track,
  breakpoints,
  useSlider,
} from '@gassan-ui'
import { Article } from '@generated'
import { cn } from '@lib/cn'
import { useWishlist } from '@lib/hooks/use-wishlist'
import { useBreakpoint } from '@lib/hooks/useBreakpoint'
import * as Elements from '@lib/kontent/models/Elements'
import { FC, Fragment, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { MarginTopProps } from 'styled-system'

const Slide = styled(Box)`
  cursor: pointer;
  display: flex;
  scroll-snap-align: start;
  position: relative;
`

type Item = Article
type Props = MarginTopProps & {
  title?: string
  isLoading?: boolean
  articles?: Item[]
  featuredImageLink?: Elements.TextElement
  featuredImage?: Elements.AssetsElement
  emarsysContainerId?: string
}

const ProductsSlider: FC<Props> = ({
  title,
  articles,
  isLoading,
  featuredImageLink,
  featuredImage,
  emarsysContainerId,
  mt = ['4rem', '4rem', '6.5rem', '6.5rem', '11.5rem'],
}) => {
  const wishlist = useWishlist()
  const trackRef = useRef<HTMLDivElement>(null)
  const [showButtons, setShowButtons] = useState(false)
  const isLarge = useBreakpoint(breakpoints.large)
  const slideCountButtonThresholdLarge = featuredImage && featuredImage.value ? 3 : 4
  const { next, prev, onTouchEnd, canMoveLeft, canMoveRight } = useSlider({
    trackRef,
    forceUpdate: isLoading,
  })
  const justifyContent = getJustifySettings()

  useEffect(() => {
    if (!isLoading && articles) {
      if (!isLarge) {
        if (articles.length > 2) {
          setShowButtons(true)
        } else {
          setShowButtons(false)
        }
      }
      if (isLarge) {
        if (articles.length > slideCountButtonThresholdLarge) {
          setShowButtons(true)
        } else {
          setShowButtons(false)
        }
      }
    }
  }, [isLarge, articles, isLoading, slideCountButtonThresholdLarge])

  return (
    <Box mt={mt}>
      <Container>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mb={['1.5rem', '1.5rem', '2rem', '2rem', '2.5rem']}
        >
          {title ? (
            <Heading variant="h2" as="h2" mb={0}>
              {title}
            </Heading>
          ) : (
            <span />
          )}
          {showButtons && (
            <Box display="flex">
              <SliderNavButton onClick={prev} direction="prev" disabled={!canMoveLeft} />
              <SliderNavButton onClick={next} direction="next" disabled={!canMoveRight} />
            </Box>
          )}
        </Flex>
        <Box width="100%" style={{ overflow: 'hidden' }}>
          <Track
            ref={trackRef}
            onTouchEnd={onTouchEnd}
            className={cn('w-[calc(100%+1rem)]', justifyContent)}
            id={emarsysContainerId}
          >
            {isLoading &&
              [...Array.from(new Array(4))].map((_, i) => (
                <Fragment key={i}>
                  <Slide minWidth={{ _: '50%', large: '25%' }}>
                    <ProductCard isLoading width="100%" />
                    <Box minWidth="1rem" height="100%" />
                  </Slide>
                </Fragment>
              ))}
            {!isLoading && articles && (
              <>
                {featuredImage && featuredImage.value && (
                  <Slide minWidth={{ _: '50%', large: '25%' }}>
                    <ProductSliderFeaturedImage
                      featuredImage={featuredImage}
                      featuredImageLink={featuredImageLink}
                    />
                  </Slide>
                )}
                {articles.map((article) => (
                  <Fragment key={article.id}>
                    <Slide minWidth={{ _: '50%', large: '25%' }}>
                      <ProductCard
                        href={`/p/${article.urlSlug}`}
                        brand={article.brand}
                        id={article.id}
                        articleType={article.articleType}
                        description={article.description}
                        images={article.images}
                        title={article.brand}
                        hidePrice={article.brand === 'TROPHY BY GASSAN' || !article.isPriceVisible}
                        subtitle={article.description}
                        price={article.price}
                        discountPrice={article.salePrice}
                        isOnWishlist={wishlist.articleIds.includes(article.id)}
                        isEmarsysItem={!!emarsysContainerId}
                        onClickWishlist={() =>
                          wishlist.toggle({
                            id: article.id,
                            title: article.brand,
                            subtitle: article.description,
                            image: article.images[0],
                          })
                        }
                        width="100%"
                      />
                      <Box minWidth="1rem" height="100%" />
                    </Slide>
                  </Fragment>
                ))}
              </>
            )}
          </Track>
        </Box>
      </Container>
    </Box>
  )

  function getJustifySettings() {
    if (!articles) return 'justify-start'
    if (isLarge && articles.length < 4) return 'justify-center'
    if (!isLarge && articles.length < 2) return 'justify-center'

    return 'justify-start'
  }
}

export default ProductsSlider
