import { parse } from '@config/theme';
export const parent = parse({
  display: 'flex',
  width: '100%'
});
export const imageParent = parse({
  width: '100%',
  position: 'relative'
}, "ik9nf4u");
export const spacer = parse({
  height: '100%'
}, "sojcm40");
export const link = "lftj0f3";
export const linkContainer = parse({
  position: 'absolute',
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'flex-end',
  color: 'white',
  p: '4'
}, "lb9d3md");

require("./FeaturedImage.styles.linaria.module.css!=!../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./FeaturedImage.styles.ts");