// @ts-strict-ignore
import { useEffect, useState } from 'react'

export const useBreakpoint = (width: string, condition: 'min' | 'max' = 'min'): boolean => {
  const [isMinWidth, setIsMinWidth] = useState<boolean>(false)
  const minMax = condition === 'min' ? 'min-width:' : 'max-width:'
  useEffect(() => {
    const mediaQuery = window.matchMedia(`(${minMax} ${width})`)

    function handleMediaQueryChange(mql) {
      setIsMinWidth(mql.matches ? true : false)
    }

    handleMediaQueryChange(mediaQuery)
    mediaQuery.addListener(handleMediaQueryChange)
    return () => {
      mediaQuery.removeListener(handleMediaQueryChange)
    }
  }, []) // eslint-disable-line

  return isMinWidth
}
