// @ts-strict-ignore
import * as styles from './FeaturedImage.styles'
import LocalizedLink from '@components/Localization/LocalizedLink'
import { Image } from '@components/gassan-ui'
import { LinariaLink } from '@components/gassan-ui/LinariaLink'
import { linariaTheme } from '@config/theme'
import { useBreakpoint } from '@lib/hooks/useBreakpoint'
import { imgix } from '@lib/imigx'
import * as Elements from '@lib/kontent/models/Elements'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

type ProductSliderFeaturedImageProps = {
  featuredImageLink?: Elements.TextElement
  featuredImage?: Elements.AssetsElement
}

export const ProductSliderFeaturedImage: FC<ProductSliderFeaturedImageProps> = ({
  featuredImage,
  featuredImageLink,
}) => {
  const { t } = useTranslation('other')
  const isMediumUp = useBreakpoint(linariaTheme.breakpoints.medium)

  return (
    <div className={styles.parent}>
      <div className={styles.imageParent}>
        <Image
          className="aspect-[5/7] h-full"
          sources={[imgix(featuredImage.value[0].url, { width: 600 })]}
          alt="Featured image"
        />
        {featuredImageLink.value && (
          <LocalizedLink href={`/${featuredImageLink.value}`} passHref>
            <a className={styles.linkContainer}>
              <LinariaLink className={styles.link} variant="grey">
                {isMediumUp ? t('viewAll') : t('view')}
              </LinariaLink>
            </a>
          </LocalizedLink>
        )}
      </div>
      <div className={styles.spacer} />
    </div>
  )
}
