import { linkStyle } from '../Typography/linariaStyles'
import * as styles from './styles'
import { cx } from '@linaria/core'
import React, { AnchorHTMLAttributes, ButtonHTMLAttributes, FC } from 'react'

type BaseLink = {
  variant?: 'brown' | 'blue' | 'grey'
}

type Link = BaseLink &
  AnchorHTMLAttributes<HTMLAnchorElement> & {
    as?: 'link'
  }

type Button = BaseLink &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    as?: 'button'
  }

type LinkType = Link | Button

type LinariaLinkProps = BaseLink & LinkType

export const LinariaLink: FC<LinariaLinkProps> = React.forwardRef((props, ref: any) => {
  const { as, children, variant = 'blue', className, ...rest } = props
  const Element: any = as === 'link' ? 'a' : 'button'
  return (
    <Element
      ref={ref}
      data-variant={variant}
      className={cx(linkStyle, styles.styles, className)}
      {...rest}
    >
      {children}
    </Element>
  )
})
