// @ts-strict-ignore
import * as S from './elements'
import { Box, LoadingBar } from '@gassan-ui'
import { formatPrice } from '@lib/formatPrice'
import { FC } from 'react'

type Props = {
  title?: string
  subtitle?: string
  price?: number
  discountPrice?: number
  isLoading?: boolean
  brand?: string
  showLightTextOnHover: boolean
  hidePrice: boolean
}

const AllOrPriceTitleVariant: FC<Props> = ({
  title,
  subtitle,
  price,
  discountPrice,
  isLoading,
  showLightTextOnHover,
  hidePrice,
}) => {
  const inner = discountPrice ? (
    <>
      <S.Price color={showLightTextOnHover ? 'white' : 'shade.800'}>
        {formatPrice(discountPrice)}
      </S.Price>
      <S.Discount color="shades.500" fontStyle="strike-trough">
        {formatPrice(price)}
      </S.Discount>
    </>
  ) : (
    price && (
      <S.Price color={showLightTextOnHover ? 'white' : 'shade.800'}>{formatPrice(price)}</S.Price>
    )
  )

  return isLoading ? (
    <S.TextContainerStatic>
      <LoadingBar mb=".25rem" width="50%" />
      <LoadingBar width="70%" />
    </S.TextContainerStatic>
  ) : (
    <>
      <S.TextContainerStatic>
        <S.Text mb={['.25rem', 0]} fontWeight={600}>
          {title}
        </S.Text>
        <S.Text>{!hidePrice && inner}</S.Text>
      </S.TextContainerStatic>
      <S.TextContainerHover>
        <S.Text color={showLightTextOnHover ? 'white' : 'shade.800'} fontWeight={600}>
          {title}
        </S.Text>
        <Box mt=".25rem">{hidePrice ? '' : inner}</Box>
        <S.Text color={showLightTextOnHover ? 'white' : 'shade.800'}>{subtitle}</S.Text>
      </S.TextContainerHover>
    </>
  )
}

export default AllOrPriceTitleVariant
