import * as styles from './LifestyleHover.styles'
import { useLifestyleHover } from './hooks/use-lifestyle-hover'
import { Image, Spinner } from '@gassan-ui'
import { imgix } from '@lib/imigx'
import { FC } from 'react'

type Props = {
  src: string
  alt: string
}

const LifestyleHover: FC<Props> = ({ src, alt }) => {
  const { status, handleImageOnLoad } = useLifestyleHover()

  return (
    <>
      <div
        className={styles.imageContainer}
        data-is-image-loaded={status === 'image-loaded' ? '' : undefined}
      >
        <Image
          placeholderSrc={imgix(src, { width: 50 })}
          sources={[
            imgix(src, { width: 350 }),
            imgix(src, { width: 350 }),
            imgix(src, { width: 600 }),
          ]}
          alt={alt}
          className="absolute inset-0"
          loading="lazy"
          onLoad={handleImageOnLoad}
        />
      </div>
      {status === 'is-loading-slow' && (
        <div className={styles.loaderContainer}>
          <Spinner size={32} />
        </div>
      )}
      <span className={styles.gradient} />
    </>
  )
}

export default LifestyleHover
