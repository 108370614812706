import { parse } from '@config/theme';
export const gradient = parse({
  width: '100%',
  position: 'absolute',
  display: 'block'
}, "g19nvmyl");
export const imageContainer = parse({
  width: '100%',
  height: '100%',
  position: 'absolute'
}, "i238cqc");
export const loaderContainer = parse({
  width: '100%',
  height: '100%',
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}, "lqqdfg2");

require("./LifestyleHover.styles.linaria.module.css!=!../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./LifestyleHover.styles.ts");