import { useCallback, useEffect, useRef, useState } from 'react'

type Status = 'loading' | 'image-loaded' | 'is-loading-slow'

export function useLifestyleHover() {
  const [status, setStatus] = useState<Status>('loading')
  const timer = useRef<NodeJS.Timeout>()

  const handleImageOnLoad = useCallback(() => {
    setStatus('image-loaded')
  }, [setStatus])

  // We'll show a loading indicator if the image hasn't loaded after 500ms
  useEffect(() => {
    timer.current = setTimeout(() => {
      if (status === 'loading') {
        setStatus('is-loading-slow')
      }
    }, 500)

    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [timer, status])

  return {
    status,
    handleImageOnLoad,
  }
}
