import { Span, breakpoints } from '@gassan-ui'
import styled, { css } from 'styled-components'
import { WidthProps, width } from 'styled-system'

export const ImagesContainer = styled.div`
  width: 100%;
  position: relative;

  &::before {
    padding-top: 100%;
    content: '';
    display: block;
  }
`

export const Text = styled(Span)`
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

Text.defaultProps = {
  as: 'span',
  fontFamily: 'body',
  fontSize: ['0.75rem', '1rem'],
  color: 'shades.800',
  lineHeight: 1.6,
}

export const Price = styled(Span)``
Price.defaultProps = {
  as: 'span',
  fontFamily: 'body',
  fontSize: ['0.75rem', '1rem'],
  color: 'white',
  lineHeight: 1.6,
}

export const Discount = styled(Span)`
  text-decoration: line-through;
`
Discount.defaultProps = {
  as: 'span',
  fontFamily: 'body',
  fontSize: ['0.75rem', '1rem'],
  lineHeight: 1.6,
  color: 'shades.500',
  ml: ['.5rem', '1rem'],
}

export const BackgroundContainer = styled.div`
  padding-bottom: 1.25rem;
  z-index: 3;
  position: relative;
  @media screen and (min-width: ${breakpoints.small}) {
    padding-bottom: 4.25rem;
  }

  &::before {
    transition: transform 0.15s ease-out;
    content: '';
    position: absolute;
    width: 100%;
    height: calc(100% + 200px);
    background: ${(p) => p.theme.colors.shades[100]};
    bottom: 0;
  }
`

export const TextContainerStatic = styled.div`
  position: relative;
  bottom: 0;
  width: 100%;
  height: auto;
  transition: all 0.15s ease 0.15s;
  padding-top: 0.75rem;
  padding-bottom: 0.25rem;
  background: #fff;
  @media screen and (min-width: ${breakpoints.small}) {
    padding-top: 1.25rem;
  }
`

export const TextContainerHover = styled.div`
  position: absolute;
  bottom: 0;
  width: calc(100% - 3rem);
  height: auto;
  padding: 1.5rem 0;
  left: 1.5rem;
  transform: translateY(24px);
  opacity: 0;
  transition: all 0.15s ease-out;
  z-index: 6;
`

type LoadingWrapperProps = WidthProps

export const LoadingWrapper = styled.div<LoadingWrapperProps>`
  ${width};
  overflow: hidden;
  display: block;
  position: relative;
  height: auto;
  pointer-events: none;
`

type AnchorProps = { hasBlendMode: boolean } & WidthProps
export const Anchor = styled.a<AnchorProps>`
  ${width};
  overflow: hidden;
  display: block;
  position: relative;
  height: auto;
  pointer-events: auto;

  ${(p) =>
    p.hasBlendMode &&
    css`
      picture {
        mix-blend-mode: darken;
      }
    `} @media(hover: hover) and (pointer: fine) {
    &:hover {
      ${BackgroundContainer}::before {
        transform: translateY(72px);
      }

      ${TextContainerStatic} {
        opacity: 0;
        transform: translateY(-1rem);
        transition: all 0.15s ease-out;
      }

      ${TextContainerHover} {
        transform: translateY(0);
        transition: all 0.15s ease;
        opacity: 1;
      }
    }
  }
`
